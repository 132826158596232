<template>
  <v-card flat>
    <v-card-title v-if="locationTitle">
      Unser Team in {{ locationTitle }}
    </v-card-title>
    <v-container grid-list-xl>
      <v-row>
        <v-col
          v-for="(item, i) in items"
          :key="i"
          cols="4"
          class="d-flex align-stretch"
        >
          <v-card width="100%">
            <v-img
              v-if="item.photo"
              :src="item.photo | mImage"
              aspect-ratio="1"
            >
              <template #placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular indeterminate />
                </v-row>
              </template>
            </v-img>
            <v-img
              v-else
              :src="placeholder"
              aspect-ratio="1"
              style="background-color: grey;"
            />
            <v-card-text class="flex-column align-start pa-2 body-2 black--text">
              <div class="font-weight-bold body-1">
                {{ item.name }}
              </div>
              <div class="body-1 pb-3">
                {{ item.position }}
              </div>
              <div v-for="(phone, p) in item.phones" :key="p">
                <v-icon left>
                  mdi-phone
                </v-icon>
                {{ phone.phone }}
              </div>
              <div v-if="item.fax">
                <v-icon left>
                  mdi-fax
                </v-icon>
                {{ item.fax }}
              </div>
              <div v-if="item.email">
                <v-icon left>
                  mdi-email
                </v-icon>
                {{ item.email }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'MTeamCards',
  props: {
    locationTitle: {
      type: String,
      default: () => ''
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    swiperOptions: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      centerInsufficientSlides: true,
      spaceBetween: 0,
      touchRatio: 1,
      autoplay: {
        delay: 3000
      }
    }
  }),
  mounted() {
    this.$emit('loaded')
  }
}
</script>
